<template>
  <div class="card">
    <img :src="filterData.links.mission_patch" alt="Avatar" style="width:100%">
    <div class="container">
      <h4><b>{{filterData.mission_name}}</b></h4>
      <ul class="mission-detail-ul">
        <li class="mission-detail-li">
          <span class="text-left-bold">Mission ids:</span>
          <ul class="mission-ids-ul">
            <li v-if="filterData.mission_id.length" v-for="mission_id in filterData.mission_id">{{mission_id}}</li>
            <li v-if="!filterData.mission_id.length">No Id assign</li>
          </ul>
        </li>
        <li class="mission-detail-li">
          <span class="text-left-bold">Launch Year:</span>
          <span class="text-right-light">{{filterData.launch_year}}</span>
        </li>
        <li class="mission-detail-li">
          <span class="text-left-bold">Successful Launch:</span>
          <span class="text-right-light">{{filterData.launch_success}}</span>
        </li>
        <li class="mission-detail-li">
          <span class="text-left-bold">Successful landing:</span>
          <span class="text-right-light">{{filterData.launch_year}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    filterData: {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
