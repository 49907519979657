<template>
  <div class="main-wrapper">
    <h3 class="spacex-heading left-align">SpaceX Launch Programs</h3>
    <div class="main-wrapper-card-filter">
      <div class="filter-wrapper">
        <div class="filter-colbox">
          <h4 class="filter-heading pd10 left-align">Filters</h4>
          <div class="all-filter-wrap">
            <span class="launch-year-span"> Launch Year</span>
            <YearFilterList @handleSelectYearEmit="handleSelectYearFilter" />
          </div>
          <span class="launch-year-span"> Successful Launch </span>
          <ul class="year-ul-wrap">
            <li class="year-li">
              <span
                :class="
                  isSuccessfullaunch === 1 && isSuccessfullaunch !== 'remove'
                    ? 'year-content active-green'
                    : 'year-content'
                "
                @click="handleSuccessLaunch(true, 1)"
                >True</span
              >
              <span
                :class="
                  isSuccessfullaunch === 2 && isSuccessfullaunch !== 'remove'
                    ? 'year-content active-green'
                    : 'year-content'
                "
                @click="handleSuccessLaunch(false, 2)"
                >false</span
              >
            </li>
          </ul>
          <span class="launch-year-span"> Successful Landing </span>
          <ul class="year-ul-wrap">
            <li class="year-li">
              <span
                :class="
                  isSuccessfulland === 1 && isSuccessfulland !== 'remove'
                    ? 'year-content active-green'
                    : 'year-content'
                "
                @click="handleSuccessLanding(true, 1)"
                >True</span
              >
              <span
                :class="
                  isSuccessfulland === 2 && isSuccessfulland !== 'remove'
                    ? 'year-content active-green'
                    : 'year-content'
                "
                @click="handleSuccessLanding(false, 2)"
                >false</span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="cards-wrapper">
        <CardComponent
          v-if="filteredData.length"
          v-for="filterData in filteredData"
          :filterData="filterData"
        />
        <h3 v-if="!filteredData.length" class="no-data-found">No Data Found</h3>
      </div>
    </div>
    <div class="foter"><span>Developed by :</span> Shubham Jain </div>
  </div>
</template>

<script>
// @ is an alias to /src
import YearFilterList from "../components/YearFilterList";
import CardComponent from "../components/CardComponent";
import { API_BASE_URL } from "../assets/utils/constant";
import axios from "axios";
export default {
  name: "Home",
  components: {
    YearFilterList,
    CardComponent,
  },
  data() {
    return {
      filteredData: [],
      isSuccessfullaunch: "remove",
      isSuccessfulland: "remove",
      isSuccessfullaunchBool: false,
      isSuccessfullandBool: false,
      selectedYear: "remove",
      limit: 100,
    };
  },
  mounted() {
    this.ApiCaller(`${API_BASE_URL}?limit=${this.limit}`);
  },
  methods: {
    ApiCaller(endurl) {
      axios
        .get(endurl)
        .then((response) => {
          if (response.status === 200) this.filteredData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleFilterApply() {
      let endpoint;
      if (
        this.isSuccessfullaunch !== "remove" &&
        this.isSuccessfulland !== "remove" &&
        this.selectedYear !== "remove"
      ) {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&launch_success=${this.isSuccessfullaunchBool}&land_success=${this.isSuccessfullandBool}&launch_year=${this.selectedYear}`;
      } else if (
        this.isSuccessfullaunch !== "remove" &&
        this.isSuccessfulland !== "remove"
      ) {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&launch_success=${this.isSuccessfullaunchBool}&land_success=${this.isSuccessfullandBool}`;
      } else if (
        this.isSuccessfullaunch !== "remove" &&
        this.selectedYear !== "remove"
      ) {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&launch_success=${this.isSuccessfullaunchBool}&launch_year=${this.selectedYear}`;
      } else if (
        this.isSuccessfulland !== "remove" &&
        this.selectedYear !== "remove"
      ) {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&land_success=${this.isSuccessfullandBool}&launch_year=${this.selectedYear}`;
      } else if (this.selectedYear !== "remove") {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&launch_year=${this.selectedYear}`;
      } else if (this.isSuccessfullaunch !== "remove") {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&launch_success=${this.isSuccessfullaunchBool}`;
      } else if (this.isSuccessfulland !== "remove") {
        endpoint = `${API_BASE_URL}?limit=${this.limit}&land_success=${this.isSuccessfullandBool}`;
      } else {
        endpoint = `${API_BASE_URL}?limit=${this.limit}`;
      }
      this.ApiCaller(endpoint);
    },
    handleSelectYearFilter(data) {
      this.selectedYear = data;
      this.handleFilterApply();
    },
    handleSuccessLaunch(value, index) {
      if (this.isSuccessfullaunch !== index) {
        this.isSuccessfullaunch = index;
      } else {
        this.isSuccessfullaunch = "remove";
      }
      this.isSuccessfullaunchBool = value;
      this.handleFilterApply();
    },
    handleSuccessLanding(value, index) {
      if (this.isSuccessfulland !== index) {
        this.isSuccessfulland = index;
      } else {
        this.isSuccessfulland = "remove";
      }
      this.isSuccessfullandBool = value;
      this.handleFilterApply();
    },
  },
};
</script>
