<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './views/Home';
export default {
  components: {
    Home,
  },
};
</script>