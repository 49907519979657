<template>
   <ul class="year-ul-wrap">
      <li class="year-li" v-for="year in yearList">
        <span :class="((isSelected === year.year1) && (isSelected !== 'remove')) ? 'year-content active-green' : 'year-content'" @click="handleSelectYear(year.year1)">{{year.year1}}</span>
        <span :class="((isSelected === year.year2) && (isSelected !== 'remove')) ? 'year-content active-green' : 'year-content'" v-if="year.year2" @click="handleSelectYear(year.year2)">{{year.year2}}</span>
      </li>
   </ul>
</template>

<script>
export default {
  name: 'YearFilterList',
  props: {
    msg: String
  },
  data(){
    return{
       isSelected:null,
       yearList:[
        {
          year1: '2006',
          year2: '2007'
        },
        {
          year1: '2008',
          year2: '2009'
        },
        {
          year1: '2010',
          year2: '2011'
        }, 
        {
          year1: '2012',
          year2: '2013'
        },
        {
          year1: '2014',
          year2: '2015'
        }, 
        {
          year1: '2016',
          year2: '2017'
        }, 
        {
          year1: '2018',
          year2: '2019'
        }, 
        {
          year1: '2020'
        },   
      ]
    }
  },
  methods:{
    handleSelectYear(year){
      if(this.isSelected !== year){
        this.isSelected = year
      }else{
        this.isSelected = 'remove'
      }
      this.$emit('handleSelectYearEmit', this.isSelected)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
